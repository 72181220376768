import { render, staticRenderFns } from "./CreatePortfolioAssetOperationModal.vue?vue&type=template&id=07fc8635"
import script from "./CreatePortfolioAssetOperationModal.vue?vue&type=script&lang=js"
export * from "./CreatePortfolioAssetOperationModal.vue?vue&type=script&lang=js"
import style0 from "./CreatePortfolioAssetOperationModal.vue?vue&type=style&index=0&id=07fc8635&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,Spinner: require('/skade-pwa/components/ui/atom/Spinner.vue').default})
