export default {
  addMessage(context, payload) {
    context.commit('ADD_MESSAGE', payload);
  },
  addConversations(context, payload) {
    context.commit('ADD_CONVERSATIONS', payload);
  },
  updateConversations(context, payload) {
    context.commit('UPDATE_CONVERSATIONS', payload);
  },
  addUsers(context, payload) {
    context.commit('ADD_USERS', payload);
  },
  updateOnlineStatus(context, payload) {
    context.commit('SET_ONLINE_STATUS', payload);
  },
  removeReaction(context, payload) {
    context.commit('REMOVE_REACTION', payload);
  },
  getMessageNotificationsAll(context, payload) {
    return this.dispatch('api/getMessageNotificationsAll', payload)
      .then((res) => {
        context.commit('SET_MESSAGE_NOTIFICATIONS', res.data);
        context.commit('SET_UNREAD_MESSAGE_NOTIFICATION_COUNT');
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  addNewMessageNotification(context, payload) {
    context.commit('ADD_NEW_MESSAGE_NOTIFICATION', payload);
  },
  addMessagesWithReplies(context, payload) {
    context.commit('ADD_MESSAGES_WITH_REPLIES', payload);
  },
  setMessageNotificationCount(context) {
    context.commit('SET_UNREAD_MESSAGE_NOTIFICATION_COUNT');
  },
  markAsReadMessageNotificationsAll(context, payload) {
    return this.dispatch('api/markAsReadMessageNotificationsAll', payload)
      .then(() => {
        context.commit('MARK_AS_READ_MESSAGE_NOTIFICATIONS', payload);
        context.commit('SET_UNREAD_MESSAGE_NOTIFICATION_COUNT');
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  removeNotification(context, payload) {
    const notifications = context.state.messageNotifications.find(
      (n) => n.messageId === payload.id
    );
    if (notifications) {
      return this.dispatch('api/removeNotification', payload)
        .then(() => {
          context.commit('REMOVE_NOTIFICATION', payload);
        })
        .catch((err) => {
          context.commit('SET_ERROR', err);
        });
    }
  },
  getProfilesMessagePrivacySettings(context, payload) {
    return this.dispatch('api/getProfilesMessagePrivacySettings', payload)
      .then((res) => {
        context.commit(
          'SET_PROFILES_MESSAGE_PRIVACY_SETTINGS',
          res?.data.privacyOption
        );
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  setProfilesMessagePrivacySettings(context, payload) {
    return context.commit(
      'SET_PROFILES_MESSAGE_PRIVACY_SETTINGS',
      payload?.privacyOption
    );
  },
  setPrivacyUpdatedDateTime(context) {
    return context.commit('SET_PRIVACY_UPDATED_DATETIME', Date.now());
  },
  setBlocksUpdatedDateTime(context) {
    return context.commit('SET_BLOCKS_UPDATED_DATETIME', Date.now());
  },
};
