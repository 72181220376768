import { render, staticRenderFns } from "./index.vue?vue&type=template&id=17d847ce"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=17d847ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowCreatePostRounded: require('/skade-pwa/components/ui/atom/buttons/ShowCreatePostRounded.vue').default})
